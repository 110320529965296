/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useParams } from 'next/navigation';
import { isActionSuccessful } from '~/lib/actions/actions.utils';
import { useSchoolCookie } from '~/lib/hooks/useSchoolCookie';
import { getUserNotifications, getUserSchoolsAction } from './notification.action';

export const NOTIFICATIONS_KEY = 'notifications';

export const useNotifications = ({ onlyNew }: { onlyNew?: boolean }) => {
  const params = useParams();
  const schoolId = String(params.schoolId);
  return useInfiniteQuery({
    queryKey: [NOTIFICATIONS_KEY, { onlyNew, schoolId }],
    queryFn: async ({ pageParam }) => {
      const result = await getUserNotifications({
        onlyNew,
        schoolId,
        page: pageParam,
      });

      if (!isActionSuccessful(result)) {
        throw new Error(result?.serverError ?? 'Something went wrong');
      }

      return result.data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
  });
};

export const useUserSchools = () => {
  const [cookie] = useSchoolCookie();
  const params = useParams();
  const schoolId = params?.schoolId ?? cookie?.schoolId;

  return useQuery({
    queryKey: ['schools', schoolId],
    queryFn: async () => {
      const result = await getUserSchoolsAction();

      if (!isActionSuccessful(result)) {
        throw new Error(result?.serverError ?? 'Something went wrong');
      }

      return result.data;
    },
  });
};
