import type { SupportedLanguage } from '@prisma/client';

// Array of main languages of users
export const LANGUAGE: Record<SupportedLanguage, { emoji: string; name: string }> = {
  en: {
    emoji: '🇬🇧',
    name: 'English',
  },
  fr: {
    emoji: '🇫🇷',
    name: 'French',
  },
} as const;
