import { formatDate } from './formatDate';

export const getElapsedTime = (ms: number) => {
  let seconds = Math.floor(ms / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  let months = Math.floor(days / 30);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;
  days = days % 30;
  months = months % 12;

  let result = '';

  if (months > 0) {
    result += `${months}mo `;
  }
  if (days > 0) {
    result += `${days}d `;
  }
  if (hours > 0) {
    result += `${hours}h `;
  }
  if (minutes > 0) {
    result += `${minutes}m `;
  }
  if (seconds > 0) {
    result += `${seconds}s`;
  }

  return result.trim() || '0';
};

const getElapsedTimeObject = (ms: number) => {
  let seconds = Math.floor(ms / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  let months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;
  days = days % 30;
  months = months % 12;

  return {
    seconds,
    minutes,
    hours,
    days,
    months,
    years,
  };
};

export const getBiggerElapsedTime = (ms: number) => {
  const elapsedTime = getElapsedTimeObject(ms);

  if (elapsedTime.years > 0) {
    return formatDate(new Date(Date.now() - ms));
  }
  if (elapsedTime.months > 0) {
    return formatDate(new Date(Date.now() - ms));
  }
  if (elapsedTime.days > 0) {
    return `${elapsedTime.days}d`;
  }
  if (elapsedTime.hours > 0) {
    return `${elapsedTime.hours}h`;
  }
  if (elapsedTime.minutes > 0) {
    return `${elapsedTime.minutes}m`;
  }
  if (elapsedTime.seconds > 0) {
    return `${elapsedTime.seconds}s`;
  }
};
