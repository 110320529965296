import type { ComponentProps, ComponentType, ReactNode } from 'react';
import ErrorBoundary from './ErrorBoundaries';

export const withErrorBoundaries = <P extends object>(
  Component: ComponentType<P>,
  fallback?: ReactNode
): ComponentType<ComponentProps<typeof Component>> => {
  const WithErrorBoundaries = (props: ComponentProps<typeof Component>) => {
    return (
      <ErrorBoundary fallback={fallback}>
        <Component {...props} />
      </ErrorBoundary>
    );
  };

  return WithErrorBoundaries;
};
