'use client';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Bell, CheckCircle, EllipsisVertical } from 'lucide-react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { toast } from 'sonner';
import { Loader } from '~/components/atoms/loader/Loader';
import { Avatar, AvatarFallback, AvatarImage } from '~/components/ui/avatar';
import { Button } from '~/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import { Popover, PopoverContent, PopoverTrigger } from '~/components/ui/popover';
import { Typo } from '~/components/ui/typography';
import { withErrorBoundaries } from '~/components/utils/withErrorBoundaries';
import { isActionSuccessful } from '~/lib/actions/actions.utils';
import { getBiggerElapsedTime } from '~/lib/date/getElapsedTime';
import { useSchoolCookie } from '~/lib/hooks/useSchoolCookie';
import { cn } from '~/lib/utils';
import {
  markAllNotificationsAsReadAction,
  markNotificationAsReadAction,
} from './notification.action';
import type { NotificationType } from './notification.query';
import {
  NOTIFICATIONS_KEY,
  useNotifications,
  useUserSchools,
} from './notification.react-query';

const NotificationsInner = () => {
  const { data } = useUserSchools();
  const [onlyNew, setOnlyNew] = useState<boolean>(true);
  const {
    data: notificationsData,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useNotifications({
    onlyNew,
  });
  const queryClient = useQueryClient();

  const readNotificationMutation = useMutation({
    mutationFn: async () => {
      const result = await markAllNotificationsAsReadAction({});

      if (!isActionSuccessful(result)) {
        toast.error(result?.serverError ?? 'Something went wrong');
        return;
      }

      await queryClient.invalidateQueries({
        queryKey: [NOTIFICATIONS_KEY, { read: undefined }],
      });
    },
  });

  const notifications = notificationsData?.pages.flatMap((d) => d.notifications);

  const isUnReadNotification = notifications?.some((d) => !d.readAt);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          size="xs"
          variant={isUnReadNotification ? 'outline' : 'ghost'}
          className="relative"
          onClick={() => {
            void refetch();
          }}
        >
          {isUnReadNotification && (
            <div className="absolute -left-1 -top-1 size-2 rounded-full bg-destructive" />
          )}
          <Bell size={12} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        style={{
          width: 'min(90vw, 400px)',
          maxHeight: 'min(70vh, 500px)',
        }}
        className="flex flex-col divide-y divide-accent p-0 "
      >
        <div className="flex items-center gap-2 p-2">
          <Typo variant="small" className="flex-1">
            Notifications
          </Typo>
          {data ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="secondary" size="sm">
                  {data.currentSchool?.name ?? 'No school'}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {data.schools.map((s) => {
                  const currentUrl = window.location.pathname;
                  const newSchoolUrl = currentUrl.replace(
                    data.currentSchool?.id ?? '[no-school-find]',
                    s.id
                  );
                  return (
                    <DropdownMenuItem asChild key={s.id}>
                      <Link href={newSchoolUrl} className="flex items-center gap-2">
                        <Avatar className="size-4 rounded-sm">
                          <AvatarFallback>{s.name[0]}</AvatarFallback>
                          {s.imageUrl && <AvatarImage src={s.imageUrl} />}
                        </Avatar>
                        <span>{s.name}</span>
                      </Link>
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          ) : null}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="sm" variant="outline">
                <EllipsisVertical size={16} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                className={cn({
                  'animate-pulse': readNotificationMutation.isPending,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  readNotificationMutation.mutate();
                }}
              >
                {readNotificationMutation.isPending ? (
                  <Loader size={16} className="mr-2" />
                ) : (
                  <CheckCircle size={16} className="mr-2" />
                )}
                Mark all as read
              </DropdownMenuItem>
              <DropdownMenuItem
                className={cn({
                  'bg-accent/50': onlyNew,
                })}
                onClick={() => setOnlyNew((d) => !d)}
              >
                {onlyNew ? 'Show all' : 'Show only new'}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="flex flex-col divide-y divide-accent overflow-auto">
          {notifications?.map((d) => (
            <NotificationItem key={d.id} notification={d} />
          ))}

          {notifications?.length === 0 ? (
            <Typo variant="muted" className="p-8">
              No notifications
            </Typo>
          ) : (
            <Button
              size="sm"
              variant="link"
              className="w-full p-4"
              onClick={async () => fetchNextPage()}
              disabled={!hasNextPage}
            >
              Load more
            </Button>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};

const NotificationItem = ({ notification }: { notification: NotificationType }) => {
  const [, setCookie] = useSchoolCookie();
  const router = useRouter();

  const bigElapsedTime = getBiggerElapsedTime(
    Date.now() - notification.createdAt.getTime()
  );

  return (
    <Link
      href={notification.actionUrl ?? '/'}
      className={cn('flex items-start gap-4 py-2 px-4 hover:bg-accent/50', {
        'opacity-80': notification.readAt,
      })}
      onClick={async (e) => {
        e.preventDefault();
        setCookie('schoolId', notification.schoolId);
        const result = await markNotificationAsReadAction({
          notificationId: notification.id,
        });

        if (!isActionSuccessful(result)) {
          toast.error(result?.serverError ?? 'Something went wrong');
          return;
        }

        router.push(notification.actionUrl ?? '/');
      }}
    >
      <div className="relative">
        {notification.emittedBy ? (
          <Avatar className="size-8">
            <AvatarFallback>{notification.emittedBy.name?.[0]}</AvatarFallback>
            {notification.emittedBy.image && (
              <AvatarImage src={notification.emittedBy.image} />
            )}
          </Avatar>
        ) : null}
        {notification.school ? (
          <Avatar className="-right absolute -bottom-1 size-4">
            <AvatarFallback>{notification.school.name[0]}</AvatarFallback>
            {notification.school.imageUrl && (
              <AvatarImage src={notification.school.imageUrl} />
            )}
          </Avatar>
        ) : null}
      </div>
      <div className="flex flex-1 flex-col items-start gap-1">
        <Typo variant="small" className="text-sm font-medium">
          {notification.title}
        </Typo>
        <Typo variant="muted" className="text-xs">
          {notification.content}
        </Typo>
      </div>
      <div className="flex flex-col items-end">
        <Typo variant="muted" className="text-xs">
          {bigElapsedTime}
        </Typo>
        {!notification.readAt && (
          <span className="block size-2 rounded-full bg-primary"></span>
        )}
      </div>
    </Link>
  );
};

export const Notifications = withErrorBoundaries(NotificationsInner);
