import { formatDate as formatDateFNS } from 'date-fns';

// take javascript date and return like `Nov 8, 2022`
export const formatDate = (date: Date) => {
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export function formatInputDate(date: Date) {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0 en JS
  const dd = String(date.getDate()).padStart(2, '0');
  const hh = String(date.getHours()).padStart(2, '0');
  const min = String(date.getMinutes()).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
}

export const formatDateShort = (date: Date) => {
  return formatDateFNS(date, 'MM/dd/yyyy');
};
